import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import iconBack from '../images/back.svg'
import moment from 'moment'

import Footer from '../components/Footer'
import Timeline from '../components/Timeline'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function EventListScreen() {

    const history = useHistory()
    const { events } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [generatedEvents, setGeneratedEvents] = useState([])
    const [timelineEvents, setTimelineEvents] = useState([])
    const latestDate = moment().add(6, 'months')

    useEffect(() => {
        generateEvents(events)
    }, [])

    useEffect(() => {
    
        let sortedEvents = generatedEvents
        sortedEvents.sort(sortByDates)
        let groupedEvents = sortedEvents.reduce((r, a) => {
            r[a.date] = r[a.date] || []
            r[a.date].push(a)
            return r;
        }, Object.create(null))
        setTimelineEvents(groupedEvents)
    
    }, [generatedEvents])


    function generateEvents(rawEvents){
        
        let newEvents = []
        let today = moment()

        rawEvents.map(e => {

            let initDate = moment(e.initial_date)

            if(initDate.isSame(today, 'day')){
                newEvents.push({
                    ...e,
                    date: initDate.format('YYYY-MM-DD'),
                })
            }
            else if(initDate.isAfter(today) && initDate.isBefore(latestDate)){
                newEvents.push({
                    ...e,
                    date: initDate.format('YYYY-MM-DD'),
                })
            }

            if(e.repeat !== 'none'){
                let futureEvents = generateFutureEvents(e, latestDate)
                newEvents = [...newEvents, ...futureEvents]
            }

        })

        setGeneratedEvents(newEvents)

    }

    function generateFutureEvents(e, until){

        let events = []
        let today = moment()
        let shouldRepeat = true
        let currentDate = moment(e.initial_date)

        while(shouldRepeat){
            let addDate = currentDate.add(1, e.repeat)
            if(addDate.isBefore(until)){

                if(addDate.isSame(today, 'day') || addDate.isAfter(today)){
                    events.push({
                        ...e,
                        date: addDate.format('YYYY-MM-DD'),
                    })
                    currentDate = addDate
                }

            }else{
                shouldRepeat = false
            }
        }

        return events

    }

    function sortByDates(a, b){
        if(a.date < b.date){ return -1 }
        if(a.date > b.date){ return 1 }
        return 0
    }

    return (
        <div className="page">
    
            <div id="contentHeader" style={{paddingBottom: 20}}>
                <div className="container extraPadding">
    
                    <div className="row" style={{marginBottom: 0}}>
                        <img onClick={() => history.goBack()} src={iconBack} className="backButton" />
                        <p className="headerTitle">{ t('boxEvents') }</p>
                    </div>
    
                </div>
            </div>
    
            <div id="content" style={{marginTop: 0}}>
                <div className="container">
    
                    <Timeline events={timelineEvents} />
    
                </div>
            </div>

            <br />

            <Footer />
    
        </div>
    )
}

export default EventListScreen