import React, { createContext, useState, useMemo, useEffect } from 'react'
import { I18n } from 'i18n-js'

import { de } from '../lang/de'
import { en } from '../lang/en'

const i18n = new I18n()
i18n.translations = {
    de: de,
    en: en,
}
i18n.locale = 'en'

export const LocalizationContext = createContext(null)

export default ({children}) => {

    const [locale, setLocale] = useState(i18n.locale);

    const data = useMemo(
        () => ({
            t: (scope, options) => i18n.t(scope, { locale, ...options }),
            locale,
            setLocale,
        }),
        [locale]
    )

    return (
        <LocalizationContext.Provider value={data}>
            {children}
        </LocalizationContext.Provider>
    )
    
}