import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import iconBack from '../images/back.svg'
import iconSearch from '../images/search.svg'
import Placeholder from '../images/placeholder.jpg'

import Footer from '../components/Footer'
import MachineStatus from '../components/MachineStatus'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function MachineListScreen() {

    const history = useHistory()
    const { machines } = useContext(Context)
    const { t } = useContext(LocalizationContext)
    const [filteredMachines, setFilteredMachines] = useState([])

    useEffect(() => {
        setFilteredMachines(machines)
    }, [machines])

    function search(e){
        let results = []
        let input = e.target.value.trim()
        let words = input.split(' ')
        words.map(word => {
            machines.map(machine => {
                if(!results.find(r => r.id === machine.id)){
                    if(machine.name && machine.name.toLowerCase().includes(word.toLowerCase())){
                        results.push(machine)
                    }
                    else if(machine.serial_nr && machine.serial_nr.toLowerCase().includes(word.toLowerCase())){
                        results.push(machine)
                    }
                }
            })
        })
        setFilteredMachines(results)
    }

    return (
    <div className="page">

        <div id="contentHeader">
            <div className="container extraPadding">

                <div className="row" style={{marginBottom: 0}}>
                    <img onClick={() => history.goBack()} src={iconBack} className="backButton" />
                    <div className="search">
                        <input type="search" placeholder={ t('search') + ' ...' } className="searchInput" onChange={search} />
                        <img src={iconSearch} className="searchButton" />
                    </div>
                </div>

            </div>
        </div>

        <div id="content">
            <div className="container">

                <div className="row">

                    <div className="box" style={{padding: 0}}>
                        
                        {filteredMachines.map(machine => (
                            <Link key={'machine' + machine.id} to={'/machine/' + machine.id} className="listItem">
                                <div className="listThumbnailContainer">
                                    <img src={machine.image_cache || Placeholder} className="listThumbnail" alt="image" />
                                </div>
                                <div>
                                    <MachineStatus active={machine.status} />
                                    <p className="folderTitle">{ machine.name }</p>
                                    <p className="light">{ t('serialnr') }: { machine.serial_nr }</p>
                                </div>
                                <div className="triangle"></div>
                            </Link>
                        ))}

                    </div>

                </div>

            </div>
        </div>

        <Footer />

    </div>
    )
}

export default MachineListScreen