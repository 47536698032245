import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function Timeline({ events }) {

    const { eventTypes, machines, language } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const TimelineItem = ({ date, event }) => {

        const machine = machines.find(m => m.id === parseInt(event.machine_id))

        let translatedName = ''
        let eventType = eventTypes.find(type => type.id === parseInt(event.event_type_id))
        if(eventType.translations){
            translatedName = language in eventType.translations ? eventType.translations[language] : eventType.name
        } else translatedName = eventType.name
        
        return (            
            <div key={event.id} className="box">
                <div className="timelineEventType">{translatedName}</div>
                <div>
                    <div>{ t('machine') }:</div>
                    <div>
                        {machine && <Link to={'/machine/' + machine.id}>{ machine.name }</Link> }
                    </div>
                </div>
            </div>
        )
    }

    return (
    <div className="timeline">
        {Object.keys(events).map(date => (
        <div key={date}>
            <div className="timelineDateContainer">
                <div className={moment(date).isSame(moment(), 'day') ? 'timelineDate today' : 'timelineDate'}>{moment(date).format(t('dateFormat'))}</div>
            </div>
            <div className="timelineBoxContainer">

                <div style={{marginLeft: 10}}>
                    {events[date].map((event, index) => (
                    <div key={'eventItem' + index}>
                        <TimelineItem date={date} event={event} />
                    </div>
                    ))}
                </div>

            </div>
        </div>
        ))}
    </div>
    )
}

export default Timeline