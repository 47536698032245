import { useContext  } from 'react'
import '../App.css'
import animLoader from '../images/loader.svg'
import { LocalizationContext } from '../scripts/LocalizationContext'

function Loader() {

    const { t } = useContext(LocalizationContext)

    return (
        <div className="loader">
            <img src={animLoader} />
            <p>{ t('loadingInfo') + ' ...' }</p>
        </div>
    )
}

export default Loader