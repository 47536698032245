import React, { useContext, useState } from 'react'

import flagDE from '../images/flags/de.svg'
import flagEN from '../images/flags/en.svg'

import { Context } from '../scripts/Context'

function LanguageSwitcher() {

    const { language, setLanguage } = useContext(Context)
    const [showOptions, setShowOptions] = useState(false)

    function getFlag(lang){
        switch (lang){
            case 'de': return flagDE
            case 'en': return flagEN
        }
    }

    function changeLanguage(lang){
        setShowOptions(false)
        localStorage.setItem('language', lang)
        setLanguage(lang)
    }

    return (
        <div className="languageSwitcher">
            <div className="languageSelected" onClick={() => setShowOptions(!showOptions)}>
                <img src={getFlag(language)} />
            </div>
            { showOptions &&
            <div className="languageOptions">
                { language !== 'de' && <div onClick={() => changeLanguage('de')}><img src={flagDE} /></div> }
                { language !== 'en' && <div onClick={() => changeLanguage('en')}><img src={flagEN} /></div> }
            </div>
            }
        </div>
    )
}

export default LanguageSwitcher