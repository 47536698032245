export const de = {
    // Languages
    'german': 'Deutsch',
    'english': 'Englisch',

    // Date Format
    'dateFormat': 'DD.MM.YYYY',
    
    // Menu Boxes
    'boxDocumentation': 'Dokumentation',
    'boxDocumentationDesc': 'Kurzeinführungen, Service Manual, CE Richtlinien uvm.',
    'boxTutorials': 'Video Tutorials',
    'boxMachines': 'Maschinen',
    'boxEvents': 'Termine',
    'boxService': 'Service',
    'boxUpload': 'Datei-Upload',
    'boxUploadDesc': 'Laden Sie hier Informationen hoch, die Sie dem Hersteller in Bezug auf diese Maschine senden möchten.',
    'boxMaintenance': 'Wartung',

    // General
    'loadingInfo': 'Lädt, bitte warten',
    'logo': 'Logo',
    'search': 'Suchen',
    'save': 'Speichern',
    'password': 'Passwort',
    'firstAndLastname': 'Vor- und Nachname',
    'email': 'E-Mail',
    'text': 'Text',
    'number': 'Zahl',
    'date': 'Datum',
    'user': 'Benutzer',
    'allUsers': 'Alle Benutzer',
    'allCustomers': 'Alle Kunden',
    'allEvents': 'Alle Termine',
    'userDeleted': 'Benutzer gelöscht',
    'system': 'System',
    'activity': 'Aktivität',
    'showMore': 'Mehr anzeigen',
    'machine': 'Maschine',
    'customer': 'Kunde',
    'createMachine': 'Maschine anlegen',
    'createCustomer': 'Kunde anlegen',
    'machineCountLabel': 'Maschine(n)',
    'subscription': 'Abonnement',
    'activeSubscription': 'Aktives Abonnement',
    'noSubscription': 'Kein Abonnement',
    'active': 'Aktiv',
    'inactive': 'Inaktiv',
    'login': 'Einloggen',
    'loginTitle': 'Login',
    'name': 'Name',
    'change': 'Ändern',
    'edit': 'Bearbeiten',
    'cancel': 'Abbrechen',
    'status': 'Status',
    'print': 'Drucken',
    'image': 'Bild',
    'qrcode': 'QR-Code',
    'location': 'Standort',
    'back': 'Zurück',
    'from': 'vom',
    'by': 'Von',
    'folders': 'Ordner',
    'files': 'Dateien',
    'filesPlural': 'Datei(en)',
    'activate': 'Aktivieren',
    'deactivate': 'Deaktivieren',
    'yes': 'Ja',
    'no': 'Nein',
    'close': 'Schliessen',
    'users': 'Benutzer',
    'serialnr': 'Seriennummer',
    'on': 'am',
    'send': 'Senden',

    // Alerts
    'alertFillFields': 'Bitte füllen Sie alle notwendigen Felder aus.',
    'alertUnknownError': 'Fehler, bitte versuchen Sie es später erneut.',
    'alertUploadInactive': 'Upload Funktion inaktiv. Bitte setzen Sie sich mit dem App Admin in Verbindung!',
    'alertEnterPin': 'Bitte geben Sie Ihre PIN ein:',
    'alertIncorrectPin': 'Die eingegebene PIN ist nicht korrekt.',

    // Errors
    'serviceUnavailable': 'Dieser Service ist leider nicht verfügbar.',
    'machineNotFound': 'Maschine nicht gefunden.',

    // Upload
    'uploadSuccess': 'Upload erfolgreich!',
    'uploadNotice': 'Klicken oder Dateien hineinziehen',
    'addFiles': 'Dateien hinzufügen',
    'sendFiles': 'Dateien senden',

    // Folders & Files
    'folderBack': 'Ordner zurück',

    // Maintenance Screen
    'allMachines': 'Alle Maschinen',
    'allCustomers': 'Alle Kunden',
    'maintenanceDetails': 'Wartungsdetails',
    'createdOn': 'Erstellt am',
    'editedOn': 'Bearbeitet am',
    'maintenancedOn': 'Durchgeführt am',
    'maintenancedBy': 'Durchgeführt von',
    'maintenancePhone': 'Rufummer für Rückfragen',
    'maintenanceDescription': 'Wartungs-Beschreibung',
    'maintenanceRemote': 'Fernwartung',
    'remote': 'Fernwartung',
    'maintenanceTitle': 'Wartungsinformationen senden',
    'successfullySent': 'Erfolgreich gesendet!',
    'maintenanceSentInfo': 'Vielen Dank, wir haben Ihre Informationen erhalten und werden uns ggf. für Rückfragen bei Ihnen melden.',
    'mandatoryFieldsInfo': 'Felder mit * sind Pflichtfelder',
}