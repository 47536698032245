import React from 'react'
import Footer from '../components/Footer'

function NotAvailableScreen({ text }) {

    return (
    <div className="page">

        <div id="content" style={{ marginTop: 0 }}>
            <div className="container">

                <div className="row">

                    <h3 style={{textAlign: 'center', width: '100%'}}>{text || 'Unbekannter Fehler'}</h3>

                </div>

            </div>
        </div>

        <Footer />

    </div>
    )
}

export default NotAvailableScreen