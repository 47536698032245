import { useContext } from 'react'
import '../App.css'
import { LocalizationContext } from '../scripts/LocalizationContext'

function MachineStatus({ active }) {

    const { t } = useContext(LocalizationContext)

    return active == 1 ? (
        <div className="status active">{ t('active') }</div>
    ) : (
        <div className="status inactive">{ t('inactive') }</div>
    )
}

export default MachineStatus