export const en = {
    // Languages
    'german': 'German',
    'english': 'English',

    // Date Format
    'dateFormat': 'MM/DD/YYYY',
    
    // Menu Boxes
    'boxDocumentation': 'Documentation',
    'boxDocumentationDesc': 'Brief introductions, service manual, CE guidelines and much more.',
    'boxTutorials': 'Video Tutorials',
    'boxMachines': 'Machines',
    'boxEvents': 'Events',
    'boxService': 'Service',
    'boxUpload': 'File upload',
    'boxUploadDesc': 'Upload information here that you would like to send to the manufacturer regarding this machine.',
    'boxMaintenance': 'Maintenance',

    // General
    'loadingInfo': 'Loading, please wait',
    'logo': 'Logo',
    'search': 'Search',
    'save': 'Save',
    'password': 'Password',
    'firstAndLastname': 'First and last name',
    'email': 'eMail',
    'text': 'Text',
    'number': 'Number',
    'date': 'Date',
    'user': 'User',
    'allUsers': 'All users',
    'allCustomers': 'All customers',
    'allEvents': 'All events',
    'userDeleted': 'User deleted',
    'system': 'System',
    'activity': 'Activity',
    'showMore': 'Show more',
    'machine': 'Machine',
    'customer': 'Customer',
    'createMachine': 'Create machine',
    'createCustomer': 'Create customer',
    'machineCountLabel': 'Machine(s)',
    'subscription': 'Subscription',
    'activeSubscription': 'Active subscription',
    'noSubscription': 'No subscription',
    'active': 'Active',
    'inactive': 'Inactive',
    'login': 'Login',
    'loginTitle': 'Login',
    'name': 'Name',
    'change': 'Change',
    'edit': 'Edit',
    'cancel': 'Cancel',
    'status': 'Status',
    'print': 'Print',
    'image': 'Image',
    'qrcode': 'QR code',
    'location': 'Location',
    'back': 'Back',
    'from': 'from',
    'by': 'From',
    'folders': 'Folders',
    'files': 'Files',
    'filesPlural': 'File(s)',
    'activate': 'Activate',
    'deactivate': 'Deactivate',
    'yes': 'Yes',
    'no': 'No',
    'close': 'Close',
    'users': 'Users',
    'serialnr': 'Serial nr.',
    'on': 'on',
    'send': 'Send',

    // Alerts
    'alertFillFields': 'Please fill out all required fields.',
    'alertUnknownError': 'Error, please try again later.',
    'alertUploadInactive': 'Upload function inactive. Please contact the app admin!',
    'alertEnterPin': 'Please enter your PIN:',
    'alertIncorrectPin': 'The entered PIN is incorrect.',

    // Errors
    'serviceUnavailable': 'Unfortunately, this service is not available.',
    'machineNotFound': 'Machine not found.',

    // Upload
    'uploadSuccess': 'Upload successful!',
    'uploadNotice': 'Click or drag & drop files',
    'addFiles': 'Add files',
    'sendFiles': 'Send files',

    // Folders & Files
    'folderBack': 'Folder back',

    // Maintenance Screen
    'allMachines': 'All machines',
    'allCustomers': 'All customers',
    'maintenanceDetails': 'Maintenance details',
    'createdOn': 'Created on',
    'editedOn': 'Edited on',
    'maintenancedOn': 'Performed on',
    'maintenancedBy': 'Performed by',
    'maintenancePhone': 'Phone number for queries',
    'maintenanceDescription': 'Maintenance description',
    'maintenanceRemote': 'Remote maintenance',
    'remote': 'Remote',
    'maintenanceTitle': 'Send maintenance information',
    'successfullySent': 'Successfully sent!',
    'maintenanceSentInfo': 'Thank you, we have received your information and will contact you if we have any questions.',
    'mandatoryFieldsInfo': 'Fields with * are mandatory',
}