import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import iconBack from '../images/back.svg'

import Footer from '../components/Footer'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function ServiceScreen() {

    const history = useHistory()
    const { serviceText } = useContext(Context)
    const { t, locale } = useContext(LocalizationContext)

    return (
    <div className="page">

        <div id="contentHeader">
            <div className="container extraPadding">

                <div className="row" style={{marginBottom: 0}}>
                    <div>
                        <img onClick={() => history.goBack()} src={iconBack} className="backButton" />
                    </div>
                    <div>
                        <p className="headerTitle">{ t('boxService') }</p>                
                    </div>
                </div>

            </div>
        </div>

        <div id="content">
            <div className="container">

                <div className="row">

                    <div className="box" style={{paddingTop: 10}} dangerouslySetInnerHTML={{__html: locale in serviceText.translations ? serviceText.translations[locale] : serviceText.value }}></div>

                </div>

            </div>
        </div>

        <Footer />

    </div>
    )
}

export default ServiceScreen