import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import iconBack from '../images/back.svg'
import loader from '../images/loader-button-light.svg'

import Footer from '../components/Footer'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'
import { axios } from '../scripts/Axios'

function MaintenanceScreen() {

    const { id } = useParams()
    const history = useHistory()

    const { machines, checkFields } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const machine = machines.find(m => m.id === parseInt(id))

    const [loading, setLoading] = useState(false)
    const [maintenance, setMaintenance] = useState({
        maintenance_date: '',
        performed_by: '',
        phone: '',
        description: '',
        remote: false,
    })
    const [maintenanceSent, setMaintenanceSent] = useState(false)
    
    function sendMaintenance(){
        let check = checkFields([maintenance], ['maintenance_date', 'performed_by', 'phone'])
        if(!check){ return }
        saveMaintenance()
    }

    async function saveMaintenance(){
        setLoading(true)
        let response = await axios.post('machine/' + id + '/maintenance', maintenance)

        setLoading(false)
        if(response.status === 200){
            setMaintenanceSent(true)
        }else{
            alert(t('alertUnknownError'))
        }
    }

    return (
    <div className="page">

        <div id="contentHeader">
            <div className="container extraPadding">

                <div className="row" style={{marginBottom: 0}}>
                    <img onClick={() => history.push('/machine/' + id)} src={iconBack} className="backButton" />
                    <div>
                        <p className="headerTitle">{ machine.name }</p>
                        <p className="headerSubtitle">{ t('serialnr') }: <strong>{ machine.serial_nr }</strong></p>                        
                    </div>
                </div>

            </div>
        </div>

        <div id="content">
            <div className="container">

                <div className="row">

                    { maintenanceSent ? (
                        <div className="box">
    
                            <h2 className="centeredTitle">{ t('successfullySent') }</h2>                            
                            <div className="row">{ t('maintenanceSentInfo') }</div>
    
                        </div>
                    ) : (
                        <div className="box">
    
                            <h2 className="centeredTitle">{ t('maintenanceTitle') }</h2>
                            
                            <div className="maintenanceForm">
                                <div className="row">
                                    <label>
                                        <span>{ t('maintenancedOn') } *</span>
                                        <input type="date" value={ maintenance.maintenance_date } onChange={e => setMaintenance({ ...maintenance, maintenance_date: e.target.value })} />
                                    </label>
                                </div>
                                <div className="row">
                                    <label>
                                        <span>{ t('maintenancedBy') } *</span>
                                        <input type="text" value={ maintenance.performed_by } onChange={e => setMaintenance({ ...maintenance, performed_by: e.target.value })} />
                                    </label>
                                </div>
                                <div className="row">
                                    <label>
                                        <span>{ t('maintenancePhone') } *</span>
                                        <input type="text" value={ maintenance.phone } onChange={e => setMaintenance({ ...maintenance, phone: e.target.value })} />
                                    </label>
                                </div>
                                <div className="row">
                                    <label>
                                        <span>{ t('maintenanceDescription') }</span>
                                        <textarea value={ maintenance.description } onChange={e => setMaintenance({ ...maintenance, description: e.target.value })}></textarea>
                                    </label>
                                </div>
                                <div className="row">
                                    <label className="checkboxContainer">                                    
                                        <input type="checkbox" checked={maintenance.remote} onChange={e => setMaintenance({ ...maintenance, remote: !maintenance.remote })} /> <span>{ t('maintenanceRemote') }</span>
                                    </label>
                                </div>
                                <div className="row" style={{ justifyContent: 'center' }}>
                                    <small>{ t('mandatoryFieldsInfo') }</small>
                                </div>
                                <div className="row" style={{ justifyContent: 'center', marginTop: 30 }}>
                                    <button onClick={sendMaintenance}>{ !loading ? t('send') : <img src={loader} /> }</button>                        
                                </div>
                            </div>
    
                        </div>
                    )}

                </div>

            </div>
        </div>

        <Footer />

    </div>
    )
}

export default MaintenanceScreen