import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import Axios from 'axios'
import { axios } from './Axios'
import { LocalizationContext } from './LocalizationContext'
import Placeholder from '../images/placeholder.jpg'

export const Context = createContext(null)

export default ({children}) => {

    const history = useHistory()
    const location = useLocation()
    const params = queryString.parse(location.search)
    const { t, setLocale } = useContext(LocalizationContext)

    const [loading, setLoading] = useState(true)
    const [language, setLanguage] = useState('de')
    const [appSettings, setAppSettings] = useState(null)

    const [user, setUser] = useState(null)
    const [token, setToken] = useState(null)

    const [scannedMachineID, setScannedMachineID] = useState(null)
    const [machines, setMachines] = useState([])
    const [machineFields, setMachineFields] = useState([])
    const [events, setEvents] = useState([])
    const [eventTypes, setEventTypes] = useState([])
    const [serviceText, setServiceText] = useState('')

    
    useEffect(() => {
        checkLanguage()
        if(params.m && params.s){
            scan()
        }else{
            checkUser()
        }
    }, [])

    useEffect(() => {
        setLocale(language)
    }, [language])

    function checkLanguage(){
        let lang = localStorage.getItem('language')
        if(lang){
            setLanguage(lang)
        }else{
            let browserLang = navigator.language || navigator.userLanguage
            if(browserLang){
                lang = browserLang.slice(0, 2)
                if(!['de', 'en'].includes(lang)){
                    lang = 'en'
                }
                localStorage.setItem('language', lang)
                setLanguage(lang)
            }
        }
    }

    async function checkUser(){
        let user = localStorage.getItem('user')
        let token = localStorage.getItem('token')
        let machineID = localStorage.getItem('scannedMachineID')
        if(user && token && machineID){
            setUser(JSON.parse(user))
            setToken(token)
            setScannedMachineID(machineID)
            //history.replace('/machine/' + machineID)
        }else{
            setLoading(false)
        }
    }

    async function scan(){
        let response = await axios.post('/scan', params)
        if(response.status === 200){

            console.log(response)
            if(response.data.checkPIN){
                let pin = prompt('Bitte geben Sie Ihren PIN ein:')
                if(pin !== null) checkPin(pin, response.data.customer_id)
                else setLoading(false)
            }else{
                if(response.data.user && response.data.token){
                    login(response.data.user, response.data.token)                    
                }else{
                    setLoading(false)
                }
            }

        }
    }

    async function checkPin(pin, customer_id){        
        let response = await axios.post('/login', {
            pin: pin,
            customer_id: customer_id,
        })
        if(response.status === 200){            
            if(response.data.user && response.data.token){
                login(response.data.user, response.data.token)
            }else{
                alert('Die eingegebene PIN ist nicht korrekt.')
                let pin = prompt('Bitte geben Sie Ihre PIN ein:')
                if(pin !== null) checkPin(pin, customer_id)
                else setLoading(false)
            }
        }
    }

    function login(user, token){
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('token', token)
        localStorage.setItem('scannedMachineID', params.m)
        setUser(user)
        setToken(token)
        setScannedMachineID(params.m)
        history.replace('/machine/' + params.m)
    }


    useEffect(() => {

        axios.get('app-settings')
        .then(response => {
            setAppSettings(JSON.parse(response.data.value))
        })

        if(user){

            Axios.all([
                axios.get('machines'),
                axios.get('events'),
                axios.get('event-types'),
                axios.get('servicetext'),
                axios.get('machine-fields'),
            ])
            .then(Axios.spread((...response) => {
                let responseMachines = response[0]
                if(responseMachines.status === 200){
                    setMachines(responseMachines.data)
                    prepareMachines(responseMachines.data)
                }
                let responseEvents = response[1]
                if(responseEvents.status === 200){
                    setEvents(responseEvents.data)
                }
                let responseEventTypes = response[2]
                if(responseEventTypes.status === 200){
                    setEventTypes(responseEventTypes.data)
                }
                let responseServiceText = response[3]
                if(responseServiceText.status === 200){
                    setServiceText(responseServiceText.data)
                }
                let responseMachineFields = response[4]
                if(responseMachineFields.status === 200){
                    setMachineFields(responseMachineFields.data)
                }
                setLoading(false)
            }))
            .catch(err => console.log(err))

        }

    }, [user])

    async function prepareMachines(machinesResponse){
        let m = await loadMachineImages(machinesResponse)
        setMachines(m)
    }

    function loadMachineImages(machinesResponse){
        return Promise.all(machinesResponse.map(async (m) => {
            let image = await loadImage(m.image_uri)
            return {
                ...m,
                image_cache: image
            }
        }))
    }

    async function loadImage(uri){
        let url = Placeholder
        if(uri){
            let response = await axios.get(uri, {responseType: 'blob'})
            if(response.status === 200){
                url = URL.createObjectURL(response.data)
            }
        }
        return Promise.resolve(url)
    }


    function checkFields(array, fields){
        let success = true
        array.map(e => {
            fields.map(field => {
                if(!e[field]){ success = false }
            })
        })
        if(!success){ alert(t('alertFillFields')) }
        return success
    }


    const data = {
        loading, setLoading,
        language, setLanguage,
        appSettings, setAppSettings,
        user, setUser,
        token, setToken,
        scannedMachineID, setScannedMachineID,
        machines, events, eventTypes, machineFields,
        serviceText,
        checkFields,
    }

    return (
        <Context.Provider value={data}>
            {children}
        </Context.Provider>
    )
    
}