import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import filesize from 'filesize'
import iconFolder from '../images/folder-empty.svg'
import iconBack from '../images/back.svg'
import {ReactComponent as IconDownload} from '../images/download.svg'
import docsLoader from '../images/loader-button.svg'

import Footer from '../components/Footer'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'
import { getMimeIcon } from '../scripts/Helpers'
import { axios } from '../scripts/Axios'

function DocumentationScreen() {

    const { id, folderId } = useParams()
    const history = useHistory()
    const { machines, language, appSettings } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const machine = machines.find(m => m.id === parseInt(id))

    const [loadingDocs, setLoadingDocs] = useState(true)

    const [currentCategory, setCurrentCategory] = useState('')
    const [folders, setFolders] = useState([])
    const [files, setFiles] = useState([])

    const [activeFolder, setActiveFolder] = useState(null)
    const [activeFolderPath, setActiveFolderPath] = useState('')

    useEffect(() => {
        getDocumentation()
    }, [])
    
    useEffect(() => setActiveFolder(null), [language])

    useEffect(() => {
        if(folderId && folders){
            setActiveFolder(folders.find(f => parseInt(f.folder_id) === parseInt(folderId) && f.category == currentCategory))
        }else{
            setActiveFolder(null)
        }
    }, [folderId, folders])

    useEffect(() => makeFolderPath(), [activeFolder])

    async function getDocumentation(){
        let response = await axios.get('machine/' + id + '/documentation')
        if(response.status === 200){
            setFolders(response.data.folders)
            setFiles(response.data.files)
        }
        setLoadingDocs(false)
    }

    function makeFolderPath(){
        let path = '/'
        if(activeFolder){
            path = ''
            let loop = true
            let currentFolder = activeFolder
            while(loop){
                path = '/' + currentFolder.name + path
                if(currentFolder.parent_id){
                    let parentFolder = folders.find(folder => parseInt(folder.folder_id) === parseInt(currentFolder.parent_id))
                    currentFolder = parentFolder
                }else{
                    loop = false
                }
            }
        }
        setActiveFolderPath(path)
    }

    function openParentFolder(){
        if(activeFolder && activeFolder.parent_id){
            history.push('/machine/' + id + '/documentation/folder/' + activeFolder.parent_id)
        }else{
            history.push('/machine/' + id + '/documentation')
        }
    }

    const viewFile = async (uri, name) => {
        let file = await loadImage(uri)
        let anchor = document.createElement('a')
        anchor.download = name
        anchor.href = file
        anchor.click()
    }
    
    const loadImage = async (uri) => {
        let url
        let response = await axios.get(uri, {responseType: 'blob'})
        if(response.status === 200){
            url = URL.createObjectURL(response.data)
        }
        return Promise.resolve(url)
    }


    return (
    <div className="page">

        <div id="contentHeader">
            <div className="container extraPadding">

                <div className="row" style={{marginBottom: 0}}>
                    <img onClick={() => history.push('/machine/' + id)} src={iconBack} className="backButton" />
                    <div>
                        <p className="headerTitle">{ machine.name }</p>
                        <p className="headerSubtitle">{ t('serialnr') }: <strong>{ machine.serial_nr }</strong></p>                        
                    </div>
                </div>

            </div>
        </div>

        <div id="content">
            <div className="container">

                <div className="row">
                    
                    <div className="box" style={{padding: 0}}>

                        <div className="folderHeader">
                            <div style={{display: 'flex'}}>
                                <div>
                                    <img src={iconFolder} className="folderIcon" />
                                </div>
                                <div>
                                    <div className="folderTitle">{ activeFolder ? activeFolder.name : t('boxDocumentation') }</div>                                    
                                    <div className="folderPath">{ activeFolderPath }</div>
                                </div>
                            </div>
                            { activeFolder && <div onClick={openParentFolder} className="folderBack">{ '... ' + t('folderBack') }</div> }
                        </div>

                        {loadingDocs &&
                        <div className="docsLoader">
                            <img src={docsLoader} />
                        </div>
                        }
                        
                        {folders.filter(f => f.language === language).filter(f => (activeFolder && parseInt(f.parent_id) === parseInt(activeFolder.folder_id) && f.category == currentCategory) || (!activeFolder && f.parent_id === null)).map(folder => {
                            let fileCount = files.filter(f => f.folder_id === folder.folder_id && f.category == folder.category).length
                            let folderCount = folders.filter(f => f.parent_id === folder.folder_id && f.category == folder.category).length
                            return (
                                <div key={'folder' + folder.id} className="listItem folder" onClick={() => {
                                        setCurrentCategory(folder.category)
                                        history.push('/machine/' + id + '/documentation/folder/' + folder.folder_id)
                                    }}>
                                    <div className="textOverflowBox">
                                        <p className="folderTitle">{ folder.name }</p>
                                        <p className="folderFileCount">
                                            { fileCount > 0 || folderCount == 0 ? fileCount + ' ' + t('filesPlural') : '' }
                                            { fileCount > 0 && folderCount > 0 ? ', ' : '' }
                                            { folderCount > 0 ? folderCount + ' ' + t('folders') : '' }
                                        </p>
                                    </div>
                                    <div className="triangle"></div>
                                </div>
                            )
                        })}

                        {files.filter(f => f.language === language).filter(f => (activeFolder && parseInt(f.folder_id) === parseInt(activeFolder.folder_id) && f.category == currentCategory) || (!activeFolder && f.folder_id === null)).map(file => (
                            <div key={'folder' + file.id} className="listItem">
                                <div>
                                    <img src={getMimeIcon(file.mime)} style={{width: 32, marginRight: 12}} />
                                </div>
                                <div className="textOverflowBox">
                                    <p className="fileTitle">{ file.name }</p>
                                    <p className="fileMeta">{ file.file.split('.')[file.file.split('.').length - 1] }, { filesize(file.size) }</p>
                                </div>
                                <div className="downloadButton" onClick={() => viewFile(file.uri, file.name)}>
                                    <IconDownload fill={appSettings.appColorPrimary} />
                                </div>
                            </div>
                        ))}

                    </div>

                </div>

            </div>
        </div>

        <Footer />

    </div>
    )
}

export default DocumentationScreen