import { useContext } from 'react'
import '../App.css'
import LanguageSwitcher from './LanguageSwitcher'
import { Context } from '../scripts/Context'

function Header() {

    const { appSettings } = useContext(Context)

    return (
        <header>
            
            {appSettings && <img src={appSettings.appLogo} id="logo" alt="logo" /> }

            <div style={{marginLeft: 'auto'}}>
                <LanguageSwitcher />
            </div>

        </header>
    )
}

export default Header