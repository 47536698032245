import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import {ReactComponent as IconDocuments} from '../images/documents.svg'
import {ReactComponent as IconTools} from '../images/tools.svg'
import {ReactComponent as IconMachine} from '../images/machine.svg'
import {ReactComponent as IconTutorials} from '../images/tutorials.svg'
import {ReactComponent as IconCalendar} from '../images/calendar.svg'
import {ReactComponent as IconService} from '../images/service.svg'
import {ReactComponent as IconUpload} from '../images/upload.svg'
import iconClose from '../images/close.svg'
import Placeholder from '../images/placeholder.jpg'

import Loader from '../components/Loader'
import Footer from '../components/Footer'
import MachineStatus from '../components/MachineStatus'
import NotAvailableScreen from './NotAvailable'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'
import { axios } from '../scripts/Axios'

function MachineScreen() {

    const { id } = useParams()
    const route = useRouteMatch()
    const { machines, events, eventTypes, machineFields, appSettings, language } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const machine = machines.find(m => m.id === parseInt(id))

    const [ftpEnabled, setFtpEnabled] = useState(false)
    const [uploadVisible, setUploadVisible] = useState(false)
    const [uploadFinished, setUploadFinished] = useState(false)
    const [uploading, setUploading] = useState(false)
    
    /* START- Ist doppelt (mehr oder weniger)! Orginal bei Events ... */

    const [generatedEvents, setGeneratedEvents] = useState([])

    function generateEvents(rawEvents){
        
        let newEvents = []
        let today = moment()

        rawEvents.map(e => {

            let initDate = moment(e.initial_date)
            let type = eventTypes.find(type => type.id === parseInt(e.event_type_id))
            let showAlways = type ? type.show_always : false

            if(initDate.isSame(today, 'day') || initDate.isAfter(today) || showAlways){
                newEvents.push({
                    ...e,
                    date: initDate.format('YYYY-MM-DD'),
                })
            }
            else if(e.repeat !== 'none'){
                let futureEvents = generateFutureEvents(e)
                newEvents = [...newEvents, ...futureEvents]
            }

        })

        setGeneratedEvents(newEvents)

    }

    function generateFutureEvents(e){

        let events = []
        let today = moment()
        let shouldRepeat = true
        let currentDate = moment(e.initial_date)

        while(shouldRepeat){
            let addDate = currentDate.add(1, e.repeat)
            if(addDate.isSame(today, 'day') || addDate.isAfter(today)){
                events.push({
                    ...e,
                    date: addDate.format('YYYY-MM-DD'),
                })
                shouldRepeat = false
            }
            currentDate = addDate
        }

        return events

    }

    /* ENDE- Ist doppelt! Orginal bei Events ... */
    
    
    useEffect(() => {
        let machineEvents = events.filter(event => parseInt(event.machine_id) === machine.id)
        generateEvents(machineEvents)
        checkFtp()
    }, [])

    async function checkFtp(){
        let response = await axios.get('machine/' + id + '/ftp')
        if(response.status === 200){
            if(response.data) setFtpEnabled(true)
        }
    }


    function showUploadPopup()
    {
        if(!ftpEnabled){
            alert(t('alertUploadInactive'))
            return
        }

        let pin = prompt(t('alertEnterPin'))
        if(pin == null || pin == '') return
        else checkPin(pin)
    }

    async function checkPin(pin){        
        let response = await axios.post('/verify-pin', {
            pin: pin,
        })
        if(response.status === 200){            
            if(response.data){
                setUploadFinished(false)
                setUploadVisible(!uploadVisible)                
            }
            else alert(t('alertIncorrectPin'))
        }
    }

    function Uploader(){

        const handleSubmit = (files, allFiles) => {
            setUploading(true)
            uploadFiles(files)
        }
      
        return (
        <div className="uploadBackground">
            <div className="uploadPopup">
                { !uploadFinished ? <h1 className="uploadHeading">{ t('boxUpload') }</h1> : <h1 className="uploadHeading green">{ t('uploadSuccess') }</h1> }
                { !uploadFinished &&
                    <Dropzone
                    onChangeStatus={() => {}}
                    onSubmit={handleSubmit}
                    accept=".bmp,.avi,.wmv,.mov,.mpeg,.mp4,.jpeg,.backup,.matb,.png,.log,.pdf,.png,.gif,.xlsx,.docx,.txt,.zip,.jpg,.mpnoz,.lcb,.lcp,.fsp"
                    maxSizeBytes={512000000} // 512MB
                    inputContent={ t('uploadNotice') }
                    inputWithFilesContent={ t('addFiles') }
                    submitButtonContent={ t('sendFiles') }
                    canCancel={false}
                    />
                }
                <div className="uploadClose" onClick={() => setUploadVisible(false)}>
                    <img src={iconClose} />
                    <span>{ t('close') }</span>
                </div>
            </div>
        </div>
        )
    }


    async function uploadFiles(files){

        try {
  
            let formData = new FormData()
            files.map((file, index) => {
                formData.append('files[]', file.file)
            })

            let response = await axios.post('machine/' + id + '/ftp/upload', formData)
            if(response.status === 200){
                setUploadFinished(true)
            }else{
                alert(t('alertUnknownError'))
            }

            setUploading(false)

        } catch(err) {
            console.log(err)
            setUploading(false)
        }

    }


    return machine ? (
    <div className="page">

        <div id="contentHeader">
            <div className="container extraPadding">

                <p className="headerTitle">{ machine.name }</p>
                <p className="headerSubtitle">{ t('serialnr') }: <strong>{ machine.serial_nr }</strong></p>

            </div>
        </div>

        <div id="content">
            <div className="container respContainer">

                <div className="respHalf">
                    <div className="row">

                        <div className="box wideBox">

                            <div className="infoContainer">
                                <MachineStatus active={machine.status} />
                                <div style={{marginBottom: 10}}>
                                    <label>{ t('location') }:</label>
                                    <p style={{whiteSpace: 'break-spaces'}}>{ machine.location }</p>
                                </div>
                                { generatedEvents.map((event, index) => {
                                    let translatedName = ''
                                    let eventType = eventTypes.find(type => type.id === parseInt(event.event_type_id))
                                    if(eventType.translations){
                                        translatedName = language in eventType.translations ? eventType.translations[language] : eventType.name
                                    } else translatedName = eventType.name
                                    
                                    return (
                                        <div key={'event' + index} style={{marginBottom: 10}}>
                                            <label>{ translatedName }</label>
                                            <p>{ t('on') } <span className="primaryBold">{moment(event.date).format(t('dateFormat'))}</span></p>
                                        </div>
                                    )
                                }) }
                                { machine.dynamicFields && machineFields.map(field => {
                                    if(field.frontend){
                                        let f = machine.dynamicFields.find(df => df.field_id == field.id)
                                        if(!f) return

                                        let translatedLabel = ''
                                        if(field.translations){
                                            translatedLabel = language in field.translations ? field.translations[language] : field.name
                                        } else translatedLabel = field.name

                                        let translatedValue = ''
                                        if(f.translations){
                                            translatedValue = language in f.translations ? f.translations[language] : f.field_value
                                        } else translatedValue = f.field_value

                                        return (
                                            <div key={'field' + f.id} style={{marginBottom: 10}}>
                                                <label>{ translatedLabel }</label>
                                                <p>{field.type == 'date' ? moment(translatedValue).format(t('dateFormat')) : translatedValue}</p>
                                            </div>
                                        )
                                    }
                                }) }
                            </div>
                            <div className="thumbnailContainer">
                                <img src={machine.image_cache || Placeholder} className="thumbnail" alt="image" />
                            </div>

                        </div>

                    </div>
                </div>

                <div className="respHalf">
                    <div className="row" style={{marginBottom: 15}}>

                        <Link to={route.url + '/documentation'} className="box iconBox wideBox">
                            <IconDocuments fill={appSettings.appColorPrimary} />
                            <div>
                                <label>{ t('boxDocumentation') }</label>
                                <p className="light">{ t('boxDocumentationDesc') }</p>
                            </div>
                        </Link>

                        <Link to={route.url + '/tutorials'} className="box iconBox">
                            <IconTutorials fill={appSettings.appColorPrimary} stroke={appSettings.appColorPrimary} />
                            <label style={{ textAlign: 'center' }}>{ t('boxTutorials') }</label>
                        </Link>

                    </div>
                    <div className="row" style={{marginBottom: 15}}>

                        <Link to="/machines" className="box iconBox">
                            <IconMachine fill={appSettings.appColorPrimary} />
                            <label style={{ textAlign: 'center' }}>{ t('boxMachines') }</label>
                        </Link>

                        <Link to="/events" className="box iconBox">
                            <IconCalendar fill={appSettings.appColorPrimary} />
                            <label style={{ textAlign: 'center' }}>{ t('boxEvents') }</label>
                        </Link>

                        <Link to="/service" className="box iconBox">
                            <IconService fill={appSettings.appColorPrimary} />
                            <label style={{ textAlign: 'center' }}>{ t('boxService') }</label>
                        </Link>

                    </div>
                    <div className="row">

                        <Link to="#" className="box iconBox wideBox" onClick={() => showUploadPopup()}>
                            <IconUpload fill={appSettings.appColorPrimary} />
                            <div>
                                <label>{ t('boxUpload') }</label>
                                <p className="light">{ t('boxUploadDesc') }</p>
                            </div>
                        </Link>

                        <Link to={route.url + '/maintenance'} className="box iconBox">
                            <IconTools fill={appSettings.appColorPrimary} />
                            <label style={{ textAlign: 'center' }}>{ t('boxMaintenance') }</label>
                        </Link>

                    </div>
                    {/* <div className="row">

                        <div className="box iconBox disabled">
                            <IconTools fill={appSettings.appColorPrimary} stroke={appSettings.appColorPrimary} />
                            <div>
                                <label>Ersatzteile</label>
                                <p className="light">(bald verfügbar)</p>
                            </div>
                        </div>

                    </div> */}
                </div>

            </div>
        </div>

        { uploadVisible && <Uploader /> }

        <Footer />

        { uploading && <Loader /> }

    </div>
    ) : <NotAvailableScreen text={ t('machineNotFound') } />
}

export default MachineScreen