import React, { useContext, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import './App.css'

import Loader from './components/Loader'
import Header from './components/Header'

import { Context } from './scripts/Context'
import { LocalizationContext } from './scripts/LocalizationContext'

import MachineScreen from './screens/Machine'
import DocumentationScreen from './screens/Documentation'
import TutorialsScreen from './screens/Tutorials'
import MachineListScreen from './screens/MachineList'
import EventListScreen from './screens/EventList'
import ServiceScreen from './screens/Service'
import MaintenanceScreen from './screens/Maintenance'
import NotAvailableScreen from './screens/NotAvailable'

function App() {

    const { user, loading, appSettings } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    useEffect(() => {
        if(appSettings){
            document.title = appSettings.companyName
            document.documentElement.style.setProperty('--color-primary', appSettings.appColorPrimary)
            document.documentElement.style.setProperty('--color-primary-trans', appSettings.appColorPrimary + '25')
            document.documentElement.style.setProperty('--color-primary-dark', appSettings.appColorPrimaryDark)
        }
    }, [appSettings])

    return (
    <div id="wrapper">

        {loading ? <Loader /> : (
            <div id="page">
                <Header />
                { user ? (
                <Switch>
                    <Route path="/" exact />
                    <Route path="/machine/:id" exact component={MachineScreen} />
                    <Route path="/machine/:id/documentation" exact component={DocumentationScreen} />
                    <Route path="/machine/:id/documentation/folder/:folderId" component={DocumentationScreen} />
                    <Route path="/machine/:id/tutorials" exact component={TutorialsScreen} />
                    <Route path="/machine/:id/tutorials/folder/:folderId" component={TutorialsScreen} />
                    <Route path="/machine/:id/maintenance" component={MaintenanceScreen} />
                    <Route path="/machines" component={MachineListScreen} />
                    <Route path="/events" component={EventListScreen} />
                    <Route path="/service" component={ServiceScreen} />
                </Switch>
                ) : (
                    <NotAvailableScreen text={ t('serviceUnavailable') } />
                )}
            </div>
        )}

    </div>
    )
}

export default App